import { useState, useEffect, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom'
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { publish, subscribe, unsubscribe } from './events';

function Page() {

    const navigate = useNavigate();
    const location = useLocation();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [sessionChecked, setSessionChecked] = useState(false);
    const [readyForSocket, setReadyForSocket] = useState(false);

    const { sendMessage, readyState } = useWebSocket(process.env.REACT_APP_API_END_POINT.replace('https://adminapi.', 'wss://adminwss.'), {
        onOpen: () => {
          // console.log('Websocket connection opened')
        },
        onClose: () => {
          // console.log('Websocket connection closed'),
        },
        shouldReconnect: (closeEvent) => true, // infinitely ?
        reconnectAttempts: null, // (combined with shouldReconnect, means infinite)
        reconnectInterval: (attemptNumber) => {
          return 5000;
        },
        onMessage: (message) => processMessages(message),
  
    }, readyForSocket);

    const processMessages = (incoming) => {
        const message = JSON.parse(incoming.data);
        if (message.type === 'reportListUpdate') {
          publish('reportListUpdate');
        } else if (message.type === 'unprocessedReportListUpdate') {
          publish('unprocessedReportListUpdate');
        } else if (message.type === 'acuityListUpdate') {
          publish('acuityListUpdate');
        } else if (message.type === 'newCommissionPayment') {
          publish('newCommissionPayment')
        }
    }

    // open and close the websocket according to logged in state
    useEffect(() => {
        if (isLoggedIn) {
            setReadyForSocket(true);
        } else {
            setReadyForSocket(false);
        }
    }, [isLoggedIn])

    // on mounting of this component, subscribe to 'ws-outgoing' events which indicate a desire to send a message over the websocket (this function then actually sends it)
    // unsubscribe on unmount
    useEffect(() => {
        const outgoingMessageHandler = (data) => {
          if (readyState === ReadyState.OPEN) {
            // however: https://github.com/robtaussig/react-use-websocket#readme
            // No more waiting for the WebSocket to open before messages can be sent. Pre-connection messages are queued up and sent on connection - 
            sendMessage(JSON.stringify(data.detail));
          }
        }
        subscribe('ws-outgoing', outgoingMessageHandler);
        return () => {
          unsubscribe('ws-outgoing', outgoingMessageHandler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const doLogout = () => {
        const url = process.env.REACT_APP_API_END_POINT + 'logout';
        fetch(url, {
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
            }),
            credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
            setIsLoggedIn(false);
            // setUsername('');
            navigate('/');
        });
    }

    const ping = () => {

        if (sessionChecked && !isLoggedIn) {

        } else {
            const url = process.env.REACT_APP_API_END_POINT + 'ping';
            fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
            }),
            credentials: "include"
            })
            .then((resp) => resp.json())
            .then((data) => {
            setSessionChecked(true);
            if (data['success']) {
                if (data['role'] !== 'admin') {
                    doLogout();
                    return;
                }
                // setUsername(data['display_username']); // this one is the function call tha goes all the way to the very parent and then back down (its used in the user dropdown menu)
                // setUserRole(data['role']);
                setIsLoggedIn(true);
                if (location.pathname === '/') {
                    navigate('/incompleteReports');
                }
            } else {
                setIsLoggedIn(false); // need to check but my hope is that when this changes the page is rerendered which will mean logged in routes are no longer accessible
                // setUsername("");
            }
            });
        }
    }

    function useInterval(callback, delay) {
        const savedCallback = useRef();
      
        // Remember the latest callback.
        useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
      
        // Set up the interval.
        useEffect(() => {
          function tick() {
            savedCallback.current();
          }
          if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
          }
        }, [delay]);
    }

    useInterval(() => { // check the current session status every 30s
        ping()
    }, 30000);

    useEffect(() => {
        // check whenever the route changes, unfortunately this combined with the one on initial page load means there are two pings to begin with
        // but this needs to be here as if the session gets killed while the user is on another page they will redirect back to the homepage in most
        // cases but the session settings are not updated, so the ping is needed as otherwise the login will redirect the user back to the report
        // list page and so on
        //
        // This can be dealt with differently, by having the 
        ping()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const appRoutes = [];
    appRoutes.push([
        <Route key="login" path='/' element={ <LoginPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> } /> ,
    ]);

    if (isLoggedIn) {
        appRoutes.push([
            <Route key="home" path='/home' element={ <HomePage {...{doLogout}} route={"dashboard"} activeTab="dashboard" /> } /> ,
            <Route key="incompleteReports" path='/incompleteReports' element={ <HomePage {...{doLogout}} route={"incompleteReports"} activeTab="incompleteReports" /> } /> ,

            <Route key="processReport" path="/processReport/:reportId" element={ <HomePage {...{doLogout}} route={"processReport"} activeTab="incompleteReports" /> } /> ,
            <Route key="processAppointment" path="/processAppointment/:appointmentUploadSetId" element={ <HomePage {...{doLogout}} route={"processAppointment"} activeTab="incompleteAppointments" /> } /> ,

            <Route key="preliminaryReports" path='/preliminaryReports' element={ <HomePage {...{doLogout}} activeTab="preliminaryReports" route={"preliminaryReports"} /> } /> ,
            <Route key="premiumReports" path='/premiumReports' element={ <HomePage {...{doLogout}} activeTab="premiumReports" route={"premiumReports"} /> } /> ,
            <Route key="questionnaireReports" path='/questionnaireReports' element={ <HomePage {...{doLogout}} activeTab="questionnaireReports" route={"questionnaireReports"} /> } /> ,
            <Route key="incompleteAppointments" path='/appointments' element={ <HomePage {...{doLogout}} activeTab="incompleteAppointments" route={"incompleteAppointments"} /> } /> ,
            <Route key="uploadAppointmentXmls" path='/appointments/:acuityId/upload' element={ <HomePage {...{doLogout}} activeTab="incompleteAppointments" route={"uploadAppointmentXmls"} /> } /> ,
            <Route key="assessorPayments" path='/assessorPayments' element={ <HomePage {...{doLogout}} activeTab="assessorPayments" route={"assessorPayments"} /> } /> ,
        ])
    }

    // add catchall route, but only after checking the session
    if (sessionChecked) {
        appRoutes.push([
            <Route key="404" path="*" element={ <Navigate to="/" /> } />
        ]);
    }

    return (
        <Routes>
            { appRoutes }
        </Routes>
    )
}

export default Page;