import React, { useState, useEffect } from 'react';
import ReportsListItem from './components/ReportsListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { subscribe, unsubscribe } from './events';

function ReportsPage(props) {

  const [reports, setReports] = useState([]);
  const [reportsLoaded, setReportsLoaded] = useState(false);

  const fetchReportList = () => {
    var fUrl = process.env.REACT_APP_API_END_POINT + 'reportslistall/?reportMode=' + props.reportMode;
      
      fetch(fUrl,
        {
          headers: {
            'Accept': 'application/json',
          },
          method: "GET",
          credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data['success']) {
            setReports(data['reports_list']);
            setReportsLoaded(true);
          } else {
            setReports([]);
            setReportsLoaded(false);
          }
        }
      );
  }

  const getReportListItem = (report) => {
    return (
      <ReportsListItem reportMode={ props.reportMode } report={ report } key={ report['report_id'] } />
    );
  }

  // on component mount, fetch the latest report list (it'll get shown) and then subscribe to messages indicating that its been updated
  // on one of those messages, fetch again
  // on unmount unsubscribe
  useEffect(() => {
    fetchReportList();
    const handleReportListUpdateMessage = (body) => {
      fetchReportList();
    };
    subscribe('reportListUpdate', handleReportListUpdateMessage);
    return () => {
      unsubscribe('reportListUpdate', handleReportListUpdateMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridTemplateColumns = (() => {
    if (props.reportMode === 'free') return '110px 4fr 110px max-content 7fr 110px 140px max-content max-content';
    if (props.reportMode === 'premium') return '110px 4fr 4fr 7fr 110px 90px max-content';
    if (props.reportMode === 'questionnaire-free') return '110px 4fr 7fr 110px max-content';
  })();

  if (!reportsLoaded) {
    return (
      <div className="my-5" style={{ textAlign: "center" }}>
        <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
      </div>
    );
  }
  
  return (
    <>
      <p>Shows up to 50 most recent reports</p>
      <div style={{display: "grid", gridTemplateColumns: gridTemplateColumns}} >
        
          {/* First, render out the grid headings */}

          {/* Date, always */}
          <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Date</strong></div>

          {/* The assessor username, premium - eg, who created the original free report */}
          {props.reportMode === 'premium' ? <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Assessor</strong></div> : null }

          {/* The report owner, always. In case of premium reports, this is the customer */}
          <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>{props.reportMode === 'premium' ? 'Customer' : 'User'}</strong></div>
          
          {/* Surveyor, only for free XML reports */}
          {props.reportMode === 'free' ? <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Surveyor ID</strong></div> : null }
          
          {/* Report ID, only for free XML reports */}
          {props.reportMode === 'free' ? <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Report ID</strong></div> : null }

          {/* Name, always */}
          <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Name</strong></div>

          {/* EPC, always */}
          <div className="dataGridCell dataGridCellHeader" style={{textAlign: "center"}}><strong>EPC</strong></div>

          {/* Flag for free XML upgraded to premium, only for free XML */}
          {props.reportMode === 'free' ? <div className="dataGridCell dataGridCellHeader" style={{textAlign: "center"}}><strong>Premium report</strong></div> : null }

          {/* Deletion status, only for free XML uploads */}
          {props.reportMode === 'free' ? <div className="dataGridCell dataGridCellHeader" style={{textAlign: "center"}}><strong>Status</strong></div> : null }

          {/* "Emailed", this is about if a premium XML report has been emailed to its owner yet, only for premium XML */}
          {props.reportMode === 'premium' ? <div className="dataGridCell dataGridCellHeader" style={{textAlign: "center"}}><strong>Emailed</strong></div> : null }
          
          {/* Actions, always */}
          <div className="dataGridCell dataGridCellHeader" style={{textAlign: "right"}}><strong>Actions</strong></div>
  
          {/* This returns all the rows */}
          {reports.map((report) => getReportListItem(report))}
    
      </div>
    </>
  );
}

export default ReportsPage;